import CircleIcon from '@mui/icons-material/Circle'
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined'
import { Box, Container, Tooltip, useTheme } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { de } from 'date-fns/locale/de'
import { enUS } from 'date-fns/locale/en-US'
import { format } from 'date-fns'
import { useMsal } from '@azure/msal-react'
import { UpdateStoreHelper } from '../source/StoreHelper'
import { useDispatch, useSelector } from 'react-redux'
import { PickersDay } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'
import axios, { AxiosRequestConfig } from 'axios'
import { useEffect, useState } from 'react'
import { setLoadingCalendar } from '../source/slice/LoadingDateSlice'
import { ICalendar } from '../interface/ICalendar'

const Calendar = () => {
    const { instance, accounts } = useMsal()
    const { t, i18n } = useTranslation()
    //const user = useSelector((state: any) => state.user?.value)
    const loadingDate: boolean = useSelector((state: any) => state.loadingDate?.value)
    const loadingCalendar: boolean = useSelector((state: any) => state.loadingDate?.loadingCalendar)
    const dispatch = useDispatch()

    //const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)

    const onDateChange = (value: Date) => {
        // getSelectedDate(value.toISOString())
        const accessTokenRequest = {
            scopes: ['openid', 'profile', 'email', 'user.read'],
            account: accounts[0],
        }
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.accessToken
                UpdateStoreHelper(format(value, 'yyyy-MM-dd'), accessToken)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const theme = useTheme()

    const accessTokenRequest = {
        scopes: ['openid', 'profile', 'email', 'user.read'],
        account: accounts[0],
    }

    const [calendarDates, setCalendarDates] = useState<ICalendar[]>()

    const loadCalendar = (month: Date) => {
        const getSelectedDate = async () => {
            await instance.initialize()
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    let accessToken: string = accessTokenResponse.accessToken
                    const config: AxiosRequestConfig = {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + accessToken,
                        },
                        data: {},
                        params: {
                            date: format(month, 'yyyy-MM-dd'),
                            /*code: process.env.REACT_APP_BACKEND_URL*/
                        },
                    }

                    axios
                        .get(`${process.env.REACT_APP_BACKEND_URL}/Calendar`, config)
                        .then((response) => {
                            var newSelectedDate = response.data
                            console.log(newSelectedDate)
                            setCalendarDates(newSelectedDate)
                            //dispatch(setSelectedDate(newSelectedDate))
                            dispatch(setLoadingCalendar(false))
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {})
        }
        dispatch(setLoadingCalendar(true))
        getSelectedDate()
    }

    const getStatus = (date: Date) => {
        if (calendarDates) {
            var calendarDate = calendarDates.filter((d: any) => new Date(d.date).getDate() === date.getDate() && new Date(d.date).getMonth() === date.getMonth() && new Date(d.date).getFullYear() === date.getFullYear())[0]
            if (calendarDate) {
                return calendarDate.status
            }
        }
        return 'OPEN'
    }

    const getStatusString = (status: string) => {
        switch (status) {
            case 'OPEN':
                return t('calendar.open')
            case 'RELEASED':
                return t('calendar.released')
            case 'SAVED':
                return t('calendar.saved')
            case 'ABSENCE':
                return t('calendar.absence')
            case 'HOLIDAY':
                return t('calendar.holiday')
            case 'WEEKEND':
                return t('calendar.weekend')
            default:
                return ''
        }
    }

    const getStatusObj = (other: any, outsideCurrentMonth: any, day: any) => {
        const colors = {
            OPEN: {
                bgcolor: theme.palette.action.active,
                bordercolor: theme.palette.success.main,
                color: 'white',
            },
            SAVED: {
                bgcolor: '#ddf0dd',
                bordercolor: '#ddf0dd',
                color: theme.palette.success.main,
            },
            RELEASED: {
                bgcolor: theme.palette.success.main,
                bordercolor: theme.palette.success.main,
                color: 'white',
            },
            ABSENCE: {
                bgcolor: theme.palette.action.selected,
                bordercolor: theme.palette.action.selected,
                color: 'white',
            },
            HOLIDAY: {
                bgcolor: theme.palette.action.selected,
                bordercolor: theme.palette.action.selected,
                color: 'white',
            },
            WEEKEND: {
                bgcolor: theme.palette.action.selected,
                bordercolor: theme.palette.action.selected,
                color: 'white',
            },
        }

        switch (getStatus(day)) {
            case 'SAVED':
                return (
                    <PickersDay
                        {...other}
                        outsideCurrentMonth={outsideCurrentMonth}
                        day={day}
                        sx={{
                            bgcolor: colors.SAVED.bgcolor,
                            borderColor: colors.SAVED.bordercolor,
                            color: colors.SAVED.color,
                        }}
                    />
                )

            case 'RELEASED':
                return (
                    <PickersDay
                        {...other}
                        outsideCurrentMonth={outsideCurrentMonth}
                        day={day}
                        sx={{
                            bgcolor: colors.RELEASED.bgcolor,
                            borderColor: colors.RELEASED.bordercolor,
                            color: colors.RELEASED.color,
                        }}
                    />
                )

            case 'OPEN':
                if (day.getDay() === 0 || day.getDay() === 6)
                    return (
                        <PickersDay
                            {...other}
                            outsideCurrentMonth={outsideCurrentMonth}
                            day={day}
                            sx={{
                                bgcolor: colors.WEEKEND.bgcolor,
                                borderColor: colors.WEEKEND.bordercolor,
                            }}
                        />
                    )
                else
                    return (
                        <PickersDay
                            {...other}
                            outsideCurrentMonth={outsideCurrentMonth}
                            day={day}
                            sx={
                                {
                                    //bgcolor: colors.WEEKEND.bgcolor,
                                    //borderColor: colors.WEEKEND.bordercolor,
                                }
                            }
                        />
                    )

            default:
                return (
                    <PickersDay
                        {...other}
                        outsideCurrentMonth={outsideCurrentMonth}
                        day={day}
                        sx={{
                            bgcolor: colors.WEEKEND.bgcolor,
                            borderColor: colors.WEEKEND.bordercolor,
                        }}
                    />
                )
        }
    }

    function ServerDay(props: any) {
        const { day, outsideCurrentMonth, ...other } = props

        return <Tooltip title={''}>{getStatusObj(other, outsideCurrentMonth, day)}</Tooltip>
    }

    useEffect(() => {
        if (!calendarDates && !loadingCalendar) {
            var date = new Date()
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
            loadCalendar(firstDay)
        }
    }, [loadingDate, loadingCalendar])

    return (
        <>
            <Container sx={{ textAlign: 'center' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === 'de' ? de : enUS}>
                    <DateCalendar
                        views={['day', 'month', 'day']}
                        onChange={(value) => onDateChange(value)}
                        /* defaultValue={new Date(selectedDate?.header?.mserp_timeprofiledate)} */
                        disabled={loadingDate || loadingCalendar}
                        slots={{
                            day: ServerDay,
                        }}
                        showDaysOutsideCurrentMonth
                        onMonthChange={(month) => loadCalendar(month)}
                    />
                </LocalizationProvider>
            </Container>

            <Container sx={{ textAlign: 'center' }}>
                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <FiberManualRecordOutlinedIcon color="disabled" sx={{ height: 8 }} />
                    {t('calendar.open')}
                </Box>
                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <FiberManualRecordOutlinedIcon color="primary" sx={{ height: 8 }} />
                    {t('calendar.selected')}
                </Box>
                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <CircleIcon style={{ color: '#ddf0dd' }} sx={{ height: 8 }} />
                    {t('calendar.saved')}
                </Box>
                <br />
                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <CircleIcon color="success" sx={{ height: 8 }} />
                    {t('calendar.released')}
                </Box>

                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <CircleIcon color="disabled" sx={{ height: 8 }} />
                    {t('calendar.weekend')}
                </Box>
                <br />
                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <CircleIcon style={{ color: '#ECDEF1' }} sx={{ height: 8 }} />
                    {t('calendar.holiday')}
                </Box>
                <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                    <CircleIcon style={{ color: 'rgb(107, 0, 138)' }} sx={{ height: 8 }} />
                    {t('calendar.absence')}
                </Box>
            </Container>
        </>
    )
}

export default Calendar
