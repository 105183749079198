import { Box, Grid, useTheme } from '@mui/material'
import Calendar from './Calendar'
import ZDEHeader from './ZDEHeader'

const Dashboard = () => {
    const theme = useTheme()

    return (
        <Grid container spacing={0} alignItems="flex-start" justifyContent="space-around">
            <Grid justifyContent="center" display="flex" item sm={12} md={8} lg={3} bgcolor={theme.palette.background.default}>
                <Box padding={2}>
                    <Calendar />
                </Box>
            </Grid>
            <Grid item sm={8} md={8} lg={9} bgcolor={theme.palette.background.paper} minHeight={'100vh'}>
                <Box padding={2}>
                    <ZDEHeader />
                </Box>
            </Grid>
        </Grid>
    )
}

export default Dashboard
