import React, { useEffect, useState } from 'react'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { useDispatch, useSelector } from 'react-redux'
import { setAlert } from '../source/slice/AlertSlice'

const CustomSnackbar = () => {
    const alert = useSelector((state: any) => state.alert.value)
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (alert !== undefined && alert !== null && alert.length !== 0 && !!alert?.message) {
            setOpen(true)
            console.log('Alert: ', alert)
        }
    }, [alert])

    const handleClose = () => {
        setOpen(false)
        dispatch(setAlert(null))
    }

    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            <Alert onClose={handleClose} severity={alert?.type} variant="filled" sx={{ width: '100%' }}>
                {alert?.message}
            </Alert>
        </Snackbar>
    )
}

export default CustomSnackbar
