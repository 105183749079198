import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import ZDELine from './ZDELine'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useTranslation } from 'react-i18next'
import { ITimecard, TimecardLine } from '../interface/ISelectedDate'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedDate } from '../source/slice/SelectedDateSlice'
import { IUser } from '../interface/IUser'

const ZDELines = () => {
    const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)
    const lines: TimecardLine[] | undefined = selectedDate?.lines
    const user: IUser = useSelector((state: any) => state.user?.value)

    const dispatch = useDispatch()

    const { t } = useTranslation()

    const filteredLines = selectedDate?.lines?.filter(line => !line.delete);

    const addNewLine = () => {
        var newEmptyLine = {
            mserp_timeprofiledate: selectedDate.header?.mserp_timeprofiledate,
            mserp_workerpersonnelnumber: selectedDate.header?.mserp_workerpersonnelnumber,
            mserp_dataareaid: user.company,
            mserp_mbelectronictimecardlineentityid: '',
            mserp_startdatetime: '',
            mserp_stopdatetime: '',
            mserp_linenumber: 0,
            mserp_projectid: '',
            mserp_projectcategoryid: '',
            mserp_timeandattendancejobid: '',
            mserp_indirectactivitycategoryid: '',
            mserp_calculatedtimeseconds: 0,
            delete: false,
        }

        dispatch(
            setSelectedDate({
                header: selectedDate.header,
                lines: [...(selectedDate.lines || []), newEmptyLine],
            })
        )
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    {/* <caption style={{ "textAlign": "end", color: "red"}}>2h von 8h noch zu verteilen</caption> */}
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Tooltip title={t('zdelines.add')}>
                                    <IconButton size="small" onClick={addNewLine} disabled={selectedDate?.header?.isTransferred}>
                                        <AddCircleIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell>{t('zdelines.project')}</TableCell>
                            <TableCell>{t('zdelines.activity')}</TableCell>
                            <TableCell>{t('zdelines.from')}</TableCell>
                            <TableCell>{t('zdelines.to')}</TableCell>
                            <TableCell>{t('zdelines.amount')}</TableCell>
                            {/* <TableCell>{t('zdelines.comment')}</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lines?.map((row, i) => (
                            <ZDELine row={row} key={i} id={i} />
                        ))}

                        {/* ?.filter((row: TimecardLine) => row.mserp_indirectactivitycategoryid === '') */}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ZDELines
