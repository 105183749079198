import axios, { AxiosRequestConfig } from 'axios'
import { BottomNavigation, Box, Button, Paper } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import SaveIcon from '@mui/icons-material/Save'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ITimecard } from '../interface/ISelectedDate'
import { useMsal } from '@azure/msal-react'
import { ICustomSnackbar } from '../interface/ICustomSnackbar'
import { setAlert } from '../source/slice/AlertSlice'
import { format } from 'date-fns'

const Footer = () => {
    const { t } = useTranslation()
    const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)
    const { instance, accounts } = useMsal()
    const dispatch = useDispatch()

    const customSnackbarMessageSuccess: ICustomSnackbar = {
        message: t('zdeheader.saveSuccess'),
        type: 'success',
    }
    const customSnackbarMessageError: ICustomSnackbar = {
        message: t('zdeheader.saveError'),
        type: 'error',
    }

    const customSnackbarMessageReleaseSuccess: ICustomSnackbar = {
        message: t('zdeheader.releaseSuccess'),
        type: 'success',
    }
    const customSnackbarMessageReleaseError: ICustomSnackbar = {
        message: t('zdeheader.releaseError'),
        type: 'error',
    }

    const handleClick = () => {
        console.log('save:', JSON.stringify(selectedDate))
        postTimecard()
    }

    const postTimecard = () => {
        const accessTokenRequest = {
            scopes: ['openid', 'profile', 'email', 'user.read'],
            account: accounts[0],
        }
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.accessToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }

                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/Timecard`, JSON.stringify(selectedDate), config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageSuccess))
                        } else {
                            dispatch(setAlert(customSnackbarMessageError))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
            })
    }

    const releaseTimecard = () => {
        const accessTokenRequest = {
            scopes: ['openid', 'profile', 'email', 'user.read'],
            account: accounts[0],
        }
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.accessToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        date: selectedDate?.header?.mserp_timeprofiledate ? format(selectedDate.header.mserp_timeprofiledate, 'yyyy-MM-dd') : '',
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }

                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/ReleaseTimecard`, null, config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageReleaseSuccess))
                        } else {
                            dispatch(setAlert(customSnackbarMessageReleaseError))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
            })
    }

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
            }}
        >
            <BottomNavigation value={''} sx={{ justifyContent: 'flex-end', verticalAlign: 'middle' }}>
                <Box sx={{ p: 1 }}>
                    {/* <Tooltip title={t('footer.totalamountdescription')}>
                        <span>
                            {t('footer.totalamount', {
                                from: format(new Date(new Date().getFullYear(), 0, 1), 'dd.MM.'),
                                to: format(new Date(), 'dd.MM.yyyy'),
                                amount: 40,
                            })}
                        </span>
                    </Tooltip> */}
                    <Button variant="outlined" size="medium" onClick={handleClick} sx={{ mx: 1 }} startIcon={<SaveIcon />} disabled={selectedDate?.header?.isTransferred}>
                        {t('footer.save')}
                    </Button>
                    <Button variant="contained" size="medium" sx={{ mx: 1 }} startIcon={<CheckIcon />} onClick={releaseTimecard} disabled={selectedDate?.header?.isTransferred}>
                        {t('footer.release')}
                    </Button>
                </Box>
            </BottomNavigation>
        </Paper>
    )
}

export default Footer
