import { IconButton, MenuItem, Skeleton, TableCell, TableRow, TextField, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IProject, IProjectAndActivity } from '../interface/IProjects'
import { ITimecard, TimecardLine } from '../interface/ISelectedDate'
import { formatISO } from 'date-fns'
import { setSelectedDate } from '../source/slice/SelectedDateSlice'
import { useEffect, useState } from 'react'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { de } from 'date-fns/locale/de'
import { enUS } from 'date-fns/locale/en-US'

const ZDELine = (props: any) => {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()
    const { row, id } = props as { row: TimecardLine; id: number }
    const projects: any = useSelector((state: any) => state.projects?.value)
    const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)
    const loadingDate: boolean = useSelector((state: any) => state.loadingDate?.value)
    const [menge, setMenge] = useState<number>(0)

    useEffect(() => {
        if (row.mserp_startdatetime && row.mserp_stopdatetime) {
            const start = new Date(row.mserp_startdatetime).getTime()
            const stop = new Date(row.mserp_stopdatetime).getTime()
            setMenge((stop - start) / 3600000) // convert milliseconds to hours
        } else {
            setMenge(0)
        }
    }, [row.mserp_startdatetime, row.mserp_stopdatetime])

    const getLineAmount = () => {
        if (row.mserp_startdatetime && row.mserp_stopdatetime) {
            const start = new Date(row.mserp_startdatetime).getTime()
            const stop = new Date(row.mserp_stopdatetime).getTime()
            return (stop - start) / 3600000 // convert milliseconds to hours
        }
        return 0
    }

    const handleDateChange = (lineno: any, fieldName: string) => (event: any) => {
        let newCurrentDate: Date
        var currentDate = selectedDate.header?.mserp_timeprofiledate
        var currentDateTime
        if (!isNaN(event.getTime()) && currentDate !== undefined && typeof currentDate === 'string') {
            newCurrentDate = new Date(currentDate)
            newCurrentDate.setHours(event.getHours())
            newCurrentDate.setMinutes(event.getMinutes())

            currentDateTime = formatISO(newCurrentDate)

            handleLineChange(lineno, fieldName, currentDateTime)
        }
    }

    const handleDropdownChange = (lineno: any, fieldName: string) => (event: any) => {
        const value = event.target.value
        handleLineChange(lineno, fieldName, value)
    }

    const handleLineChange = (lineno: any, fieldName: string, newValue: any) => {
        var newtimecardlines: TimecardLine[] | undefined = selectedDate?.lines
        if (newtimecardlines !== undefined) {
            let newtimecardlinesCopy = [...newtimecardlines]
            newtimecardlinesCopy[lineno] = {
                ...newtimecardlinesCopy[lineno],
                [fieldName]: newValue,
            }
            console.log('projektvalue_', lineno, fieldName, newValue, newtimecardlinesCopy)

            var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
            if (newselectedDate !== undefined && newselectedDate.lines !== undefined) {
                newselectedDate.lines = newtimecardlinesCopy
            }
            dispatch(setSelectedDate(newselectedDate))
        }
    }

    const handleCopyLine = (line: TimecardLine) => {
        const newLine = {
            ...line,
            mserp_mbelectronictimecardlineentityid: '',
        }

        const newSelectedDate = {
            ...selectedDate,
            lines: [...(selectedDate?.lines || []), newLine],
        }

        console.log('Copying line. Day: ', selectedDate)
        console.log('Copying line. Line: ', line)

        dispatch(setSelectedDate(newSelectedDate))
    }

    const handleDeleteLine = (lineno: number) => {
        handleLineChange(lineno, 'delete', true)
    }

    const displayRow = row.mserp_indirectactivitycategoryid === '' && !row.delete

    useEffect(() => {
        console.log(displayRow, row.mserp_indirectactivitycategoryid === '', !row.delete)
    }, [selectedDate, projects])

    return displayRow ? (
        <TableRow
            key={row.mserp_mbelectronictimecardlineentityid}
            sx={{
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
            }}
        >
            <TableCell>
                <Tooltip title={t('zdelines.delete')}>
                    <IconButton size="small" onClick={() => handleDeleteLine(id)} disabled={selectedDate?.header?.isTransferred}>
                        <DeleteOutlineIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('zdelines.copy')}>
                    <IconButton size="small" onClick={() => handleCopyLine(row)} disabled={selectedDate?.header?.isTransferred}>
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell component="th" scope="row">
                {!loadingDate ? (
                    <TextField select defaultValue={row.mserp_projectid} size="small" fullWidth onChange={handleDropdownChange(id, 'mserp_projectid')} disabled={selectedDate?.header?.isTransferred}>
                        {projects?.map((project: IProject, i: number) => (
                            <MenuItem key={project.mserp_projectid + `_` + i} value={project.mserp_projectid} selected>
                                {project.mserp_projectid} - {project.mserp_projectname}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : (
                    <Skeleton variant="text" />
                )}
            </TableCell>
            <TableCell>
                {!loadingDate ? (
                    <TextField select defaultValue={row.mserp_timeandattendancejobid} size="small" fullWidth onChange={handleDropdownChange(id, 'mserp_timeandattendancejobid')} disabled={selectedDate?.header?.isTransferred}>
                        {selectedDate?.lines ? (
                            projects
                                ?.filter((proj: IProject) => proj.mserp_projectid === row.mserp_projectid)
                                .map((project: IProject) =>
                                    project.activities?.map((activity: IProjectAndActivity, i: number) => (
                                        <MenuItem key={activity?.mserp_projectid + `_` + i} value={activity?.mserp_jobid} selected>
                                            {activity?.mserp_jobid} - {activity?.mserp_description}
                                        </MenuItem>
                                    ))
                                )
                        ) : (
                            <></>
                        )}
                    </TextField>
                ) : (
                    <Skeleton variant="text" />
                )}
            </TableCell>
            <TableCell>
                {!loadingDate ? (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === 'de' ? de : enUS}>
                        <TimePicker
                            views={['hours', 'minutes']}
                            ampm={false}
                            slotProps={{ textField: { size: 'small' } }}
                            sx={{ width: '120px' }}
                            defaultValue={row.mserp_startdatetime ? new Date(row.mserp_startdatetime) : null}
                            onChange={handleDateChange(id, 'mserp_startdatetime')}
                            disabled={selectedDate?.header?.isTransferred}
                        />
                    </LocalizationProvider>
                ) : (
                    <Skeleton variant="text" />
                )}
            </TableCell>
            <TableCell>
                {!loadingDate ? (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === 'de' ? de : enUS}>
                        <TimePicker
                            views={['hours', 'minutes']}
                            ampm={false}
                            slotProps={{ textField: { size: 'small' } }}
                            sx={{ width: '120px' }}
                            defaultValue={row.mserp_stopdatetime ? new Date(row.mserp_stopdatetime) : null}
                            onChange={handleDateChange(id, 'mserp_stopdatetime')}
                            disabled={selectedDate?.header?.isTransferred}
                        />
                    </LocalizationProvider>
                ) : (
                    <Skeleton variant="text" />
                )}
            </TableCell>
            <TableCell>
                {!loadingDate ? (
                    <>
                        <TextField value={getLineAmount().toFixed(2)} type="number" size="small" disabled />
                    </>
                ) : (
                    <Skeleton variant="text" />
                )}
            </TableCell>
        </TableRow>
    ) : (
        <></>
    )
}

export default ZDELine
