import { configureStore } from '@reduxjs/toolkit'
import userSlice from './slice/UserSlice'
import alertSlice from './slice/AlertSlice'
import selectedDateSlice from './slice/SelectedDateSlice'
import ProjectsSlice from './slice/ProjectsSlice'
import loadingDateSlice from './slice/LoadingDateSlice'
import themeSlice from './slice/ThemeSlice'

export const store = configureStore({
    reducer: {
        user: userSlice,
        alert: alertSlice,
        selectedDate: selectedDateSlice,
        projects: ProjectsSlice,
        loadingDate: loadingDateSlice,
        theme: themeSlice,
    },
})
