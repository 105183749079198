import React, { useEffect } from 'react'
import { Divider, Grid, Skeleton, TextField, Button, Box, Typography, Chip } from '@mui/material'
import ZDELines from './ZDELines'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { format, formatISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { de } from 'date-fns/locale/de'
import { enUS } from 'date-fns/locale/en-US'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import { ITimecard, TimecardLine } from '../interface/ISelectedDate'
import { setSelectedDate } from '../source/slice/SelectedDateSlice'
import { IUser } from '../interface/IUser'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { setAlert } from '../source/slice/AlertSlice'
import axios, { AxiosRequestConfig } from 'axios'
import { useMsal } from '@azure/msal-react'
import { ICustomSnackbar } from '../interface/ICustomSnackbar'
import { UpdateStoreHelper } from '../source/StoreHelper'

const ZDEHeader = () => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)
    const user: IUser = useSelector((state: any) => state.user?.value)
    const loadingDate: boolean = useSelector((state: any) => state.loadingDate?.value)

    let fromTime: string | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid === `${process.env.REACT_APP_JID_START}`)[0]?.mserp_startdatetime
    let toTime: string | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid === `${process.env.REACT_APP_JID_STOP}`)[0]?.mserp_stopdatetime
    let breakfromTime: string | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid === `${process.env.REACT_APP_JID_BREAK}`)[0]?.mserp_startdatetime
    let breaktoTime: string | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid === `${process.env.REACT_APP_JID_BREAK}`)[0]?.mserp_stopdatetime

    let breakTime: number = 0.0
    if (breakfromTime !== undefined && breaktoTime !== undefined) {
        breakTime = (new Date(breaktoTime).getTime() - new Date(breakfromTime).getTime()) / 1000 / 60 / 60
    }

    let travelTime: number = selectedDate?.header?.mserp_mbquantitytravel ? (isNaN(selectedDate?.header?.mserp_mbquantitytravel) ? 0 : selectedDate?.header?.mserp_mbquantitytravel) : 0

    let workingTime: number = 0.0
    if (fromTime !== undefined && toTime !== undefined) {
        workingTime = (new Date(toTime).getTime() - new Date(fromTime).getTime()) / 1000 / 60 / 60 - breakTime
    }

    let workingtimeAccordingArbZG: number = 0.0
    if (fromTime !== undefined && toTime !== undefined) {
        workingtimeAccordingArbZG = (new Date(toTime).getTime() - new Date(fromTime).getTime()) / 1000 / 60 / 60 - breakTime - travelTime
    }

    const sumMenge =
        selectedDate?.lines?.reduce((sum, line) => {
            if (!line.mserp_startdatetime || !line.mserp_stopdatetime || line.mserp_timeandattendancejobid === process.env.REACT_APP_JID_BREAK) return sum
            const start = new Date(line.mserp_startdatetime)
            const stop = new Date(line.mserp_stopdatetime)
            const duration = (stop.getTime() - start.getTime()) / (1000 * 60 * 60)
            return sum + duration
        }, 0) || 0

    const remainingTime = workingTime - sumMenge

    console.log('workingTime', workingTime)
    console.log('sumMenge', sumMenge)
    console.log('remainingTime', remainingTime)

    const workingTimeDisplay = isNaN(workingTime) ? 0 : workingTime
    const remainingTimeDisplay = isNaN(remainingTime) ? 0 : remainingTime

    console.log('breakTime', breakTime)
    console.log('fromTime', fromTime)
    console.log('toTime', toTime)

    const handleDateChange = (jobId: string, fieldName: string) => (event: any) => {
        let newCurrentDate: Date
        var currentDate = selectedDate.header?.mserp_timeprofiledate
        var currentDateTime
        if (!isNaN(event?.getTime()) && currentDate !== undefined && typeof currentDate === 'string') {
            newCurrentDate = new Date(currentDate)
            newCurrentDate.setHours(event.getHours())
            newCurrentDate.setMinutes(event.getMinutes())
            currentDateTime = formatISO(newCurrentDate)

            handleLineChange(jobId, fieldName, currentDateTime)
        }
    }

    const handleLineChange = (jobId: string, fieldName: string, newValue: any) => {
        var newtimecardlines: TimecardLine[] | undefined = selectedDate?.lines
        if (newtimecardlines !== undefined) {
            let newtimecardlinesCopy = [...newtimecardlines]

            if (selectedDate !== undefined && selectedDate.lines !== undefined) {
                var index = selectedDate?.lines?.findIndex((line: TimecardLine) => line.mserp_timeandattendancejobid === jobId)

                if (jobId === `${process.env.REACT_APP_JID_START}` || jobId === `${process.env.REACT_APP_JID_STOP}`) {
                    newtimecardlinesCopy[index] = {
                        ...newtimecardlinesCopy[index],
                        mserp_startdatetime: newValue,
                        mserp_stopdatetime: newValue,
                    }
                } else {
                    newtimecardlinesCopy[index] = {
                        ...newtimecardlinesCopy[index],
                        [fieldName]: newValue,
                    }
                }

                var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
                if (newselectedDate !== undefined && newselectedDate.lines !== undefined) {
                    newselectedDate.lines = newtimecardlinesCopy
                }
                dispatch(setSelectedDate(newselectedDate))
            }
        }
    }

    const handleHeaderNumberChange = (fieldName: string) => (event: any) => {
        var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
        const newValue = event.target.value

        if (newselectedDate !== undefined && newselectedDate.header !== undefined && newValue !== undefined) {
            newselectedDate.header[fieldName] = Number(newValue)
        }
        dispatch(setSelectedDate(newselectedDate))
    }

    const formatDate = () => {
        return format(new Date(selectedDate?.header?.mserp_timeprofiledate ?? new Date().toISOString()), 'cccccc. dd.MM.yyyy', {
            locale: de,
        })
    }

    const { instance, accounts } = useMsal()

    const customSnackbarMessageSuccess: ICustomSnackbar = {
        message: t('zdeheader.deleteSuccess'),
        type: 'success',
    }
    const customSnackbarMessageError: ICustomSnackbar = {
        message: t('zdeheader.deleteError'),
        type: 'error',
    }

    const deleteTimecard = () => {
        const accessTokenRequest = {
            scopes: ['openid', 'profile', 'email', 'user.read'],
            account: accounts[0],
        }
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.accessToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        date: selectedDate?.header?.mserp_timeprofiledate ? format(selectedDate.header.mserp_timeprofiledate, 'yyyy-MM-dd') : '',
                    },
                }

                axios
                    .delete(`${process.env.REACT_APP_BACKEND_URL}/Timecard`, config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageSuccess))
                            if (selectedDate?.header?.mserp_timeprofiledate) UpdateStoreHelper(format(selectedDate.header.mserp_timeprofiledate, 'yyyy-MM-dd'), accessToken)
                        } else {
                            dispatch(setAlert(customSnackbarMessageError))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
            })
    }

    const handleResetDay = () => {
        const resetSelectedDate: ITimecard = {
            header: {
                ...selectedDate.header,
                mserp_mbquantitylegal: 0,
                mserp_mbquantitytravel: 0,
                mserp_mbelectronictimecardheaderentityid: '',
                mserp_workerpersonnelnumber: '',
                mserp_dataareaid: '',
                mserp_timeprofileid: '',
                mserp_timeprofiledate: selectedDate.header?.mserp_timeprofiledate || '',
                mserp_istransferred: 200000000,
                isTransferred: false,
                status: 'OPEN',
            },
            lines: [],
        }

        dispatch(setSelectedDate(resetSelectedDate))
    }

    const getStatusLabel = (status: string) => {
        switch (status) {
            case 'OPEN':
                return t('calendar.open')
            case 'RELEASED':
                return t('calendar.released')
            case 'SAVED':
                return t('calendar.saved')
            case 'ABSENCE':
                return t('calendar.absence')
            case 'HOLIDAY':
                return t('calendar.holiday')
            case 'WEEKEND':
                return t('calendar.weekend')
            default:
                return ''
        }
    }

    const getStatusStringWithBadge = (status: string) => {
        switch (status) {
            case 'OPEN':
                return (
                    <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        <FiberManualRecordOutlinedIcon color="disabled" sx={{ height: 8 }} />
                        {t('calendar.open')}
                    </Box>
                )
            case 'RELEASED':
                return (
                    <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        <CircleIcon color="success" sx={{ height: 8 }} />
                        {t('calendar.released')}
                    </Box>
                )
            case 'SAVED':
                return (
                    <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        <CircleIcon style={{ color: '#ddf0dd' }} sx={{ height: 8 }} />
                        {t('calendar.saved')}
                    </Box>
                )
            case 'ABSENCE':
                return (
                    <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        <CircleIcon style={{ color: 'rgb(107, 0, 138)' }} sx={{ height: 8 }} />
                        {t('calendar.absence')}
                    </Box>
                )
            case 'HOLIDAY':
                return (
                    <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        <CircleIcon style={{ color: '#ECDEF1' }} sx={{ height: 8 }} />
                        {t('calendar.holiday')}
                    </Box>
                )
            case 'WEEKEND':
                return (
                    <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        <CircleIcon color="disabled" sx={{ height: 8 }} />
                        {t('calendar.weekend')}
                    </Box>
                )
            default:
                return ''
        }
    }

    const getStatusChip = (status: string) => {
        switch (status) {
            case 'OPEN':
                return <Chip label={t('calendar.open')} color="default" variant="outlined" />
            case 'RELEASED':
                return <Chip label={t('calendar.released')} color="success" variant="filled" />
            case 'SAVED':
                return <Chip label={t('calendar.saved')} color="success" variant="outlined" />
            case 'ABSENCE':
                return <Chip label={t('calendar.absence')} color="primary" style={{ color: 'rgb(107, 0, 138)' }} variant="filled" />
            case 'HOLIDAY':
                return <Chip label={t('calendar.holiday')} color="primary" style={{ color: 'rgb(107, 0, 138)' }} variant="filled" />
            case 'WEEKEND':
                return <Chip label={t('calendar.open')} color="default" variant="outlined" />
            default:
                return ''
        }
    }

    useEffect(() => {}, [selectedDate, user])

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <span style={{ fontSize: '16pt' }}>
                    {!loadingDate && user ? (
                        <>
                            {formatDate()} | ({user.resourceno}) {getStatusChip(selectedDate?.header?.status || '')}
                        </>
                    ) : (
                        <Skeleton variant="text" width="300px" sx={{ display: 'inline-block' }} />
                    )}
                </span>
                <Button variant="outlined" onClick={deleteTimecard} startIcon={<RestartAltIcon />} color="error" disabled={selectedDate?.header?.isTransferred}>
                    {t('zdeheader.resetDay')}
                </Button>
            </Box>
            <Divider sx={{ marginY: '8px' }} />

            <Grid container spacing={2} alignItems="center">
                {/* <Grid item xs={4}>
                    <span>{t('zdeheader.state')}</span>
                </Grid>
                <Grid item xs={8}>
                    <span>
                        {!loadingDate ? (
                            <Typography display="inline-block" variant="body1" sx={{ lineHeight: '40px' }}>
                                {getStatusStringWithBadge(selectedDate?.header?.status || '')}
                            </Typography>
                        ) : (
                            <Skeleton variant="text" width="50%" />
                        )}
                    </span>
                </Grid> */}
                <Grid item xs={4}>
                    <Typography variant="body1">{t('zdeheader.workingtime')}</Typography>
                </Grid>
                <Grid item xs={8} alignItems="center">
                    {!loadingDate ? (
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === 'de' ? de : enUS}>
                            <TimePicker
                                views={['hours', 'minutes']}
                                ampm={false}
                                slotProps={{ textField: { size: 'small' } }}
                                sx={{ width: '120px' }}
                                defaultValue={fromTime ? new Date(fromTime) : null}
                                onChange={handleDateChange(`${process.env.REACT_APP_JID_START}`, 'mserp_startdatetime')}
                                disabled={selectedDate?.header?.isTransferred}
                            />
                            <Typography display="inline-block" variant="body1" sx={{ lineHeight: '40px' }} px={2}>
                                {t('zdeheader.to')}
                            </Typography>
                            <TimePicker
                                views={['hours', 'minutes']}
                                ampm={false}
                                slotProps={{ textField: { size: 'small' } }}
                                sx={{ width: '120px' }}
                                defaultValue={toTime ? new Date(toTime) : null}
                                onChange={handleDateChange(`${process.env.REACT_APP_JID_STOP}`, 'mserp_startdatetime')}
                                disabled={selectedDate?.header?.isTransferred}
                            />
                        </LocalizationProvider>
                    ) : (
                        <Skeleton variant="text" width="50%" />
                    )}
                </Grid>

                <Grid item xs={4}>
                    <span>{t('zdeheader.break')}</span>
                </Grid>

                <Grid item xs={8}>
                    {!loadingDate ? (
                        <>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === 'de' ? de : enUS}>
                                <TimePicker
                                    views={['hours', 'minutes']}
                                    ampm={false}
                                    slotProps={{ textField: { size: 'small' } }}
                                    sx={{ width: '120px' }}
                                    defaultValue={breakfromTime ? new Date(breakfromTime) : null}
                                    onChange={handleDateChange(`${process.env.REACT_APP_JID_BREAK}`, 'mserp_startdatetime')}
                                    disabled={selectedDate?.header?.isTransferred}
                                />
                                <Typography display="inline-block" variant="body1" sx={{ lineHeight: '40px' }} px={2}>
                                    {t('zdeheader.to')}
                                </Typography>
                                <TimePicker
                                    views={['hours', 'minutes']}
                                    ampm={false}
                                    slotProps={{ textField: { size: 'small' } }}
                                    sx={{ width: '120px' }}
                                    defaultValue={breaktoTime ? new Date(breaktoTime) : null}
                                    onChange={handleDateChange(`${process.env.REACT_APP_JID_BREAK}`, 'mserp_stopdatetime')}
                                    disabled={selectedDate?.header?.isTransferred}
                                />
                            </LocalizationProvider>
                            <TextField value={breakTime ? breakTime.toFixed(2) : 0} type="number" size="small" disabled sx={{ width: '120px' }} />
                        </>
                    ) : (
                        <Skeleton variant="text" width="50%" />
                    )}
                </Grid>

                <Grid item xs={4}>
                    <span>{t('zdeheader.traveltime')}</span>
                </Grid>
                <Grid item xs={8}>
                    {!loadingDate ? (
                        <TextField
                            defaultValue={selectedDate?.header?.mserp_mbquantitytravel}
                            onChange={handleHeaderNumberChange('mserp_mbquantitytravel')}
                            type="number"
                            size="small"
                            sx={{ width: '120px' }}
                            disabled={selectedDate?.header?.isTransferred}
                        />
                    ) : (
                        <Skeleton variant="text" width="50%" />
                    )}
                </Grid>

                <Grid item xs={4}>
                    <span>{t('zdeheader.workingtimeAccordingArbZG')}</span>
                </Grid>
                <Grid item xs={8}>
                    {!loadingDate ? <TextField type="number" disabled size="small" value={workingtimeAccordingArbZG.toFixed(2)} sx={{ width: '120px' }} /> : <Skeleton variant="text" width="50%" />}
                </Grid>
            </Grid>
            <br></br>
            <span style={{ fontSize: '16pt' }}>
                Buchung der Zeiten {remainingTimeDisplay.toFixed(2)}h von {workingTimeDisplay.toFixed(2)}h zu verteilen
            </span>
            <Divider />
            <ZDELines />
        </>
    )
}

export default ZDEHeader
